import { defineStore } from 'pinia';
import type { Civilities, Countries } from './types/userStoreTypes';
export const useUserStore = defineStore({
  id: 'useUserStore',
  state: () => ({
    isCustomer: <boolean>false,
    civilities: <Civilities>[],
    countries: <Countries>[],
    user: <any>null
  }),
  actions: {
    getCountryById(id: number) {
      return this.countries.filter(country => country.CountryID === id)[0]
    },
    getCivilityByTitle(title: string) {
      return this.civilities.filter(civility => civility.abbreviation === title)[0]
    }
  }
});